import './App.css';
import { observer } from 'mobx-react';
import { Routes, Route, redirect, Navigate } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import Header from './components/Header';
import PrivacyPolicy from './components/PrivacyPolicy';
import Footer from './components/Footer';
import PosterPage from './components/PosterPage';
import { useEffect, useRef } from 'react';

const RedirectPage = () => {
  const linkRef = useRef(null)
  useEffect(() => {
    if (linkRef.current) {
      linkRef.current.click()
    }
  }, [])
  return (
    <a href="https://postlify.live" ref={linkRef}>Go</a>
  );
};

function App() {

  return (
    <>
      <Header />
      <Container>
        <Row className='app-container'>
            <Routes>
              <Route
                path="/"
                element={<RedirectPage />}
                />
              <Route
                path='/:eventSlug'
                element={<PosterPage />}
              />
              <Route
                path='/privacy-policy'
                element={<PrivacyPolicy />}
              />
            </Routes>
        </Row>
      </Container>
      <Footer />
    </>


  );
}

export default observer(App);
