import { NavLink } from "react-router-dom";
import { observer } from "mobx-react";
import { Nav, Navbar, NavbarBrand } from "reactstrap";
import store from "../store";
const Header = () => {
    const handleClick = () => {
        const element = document.getElementById('upload-btn');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
    return (
        <header>
            <Navbar
                className=""
                style={{ backgroundColor: 'black', height: '5rem' }}
                dark
            >
                <Nav>
                    {!store.allowScroll ?
                    <a href="http://postlify.live" className="logo" target="_blank">
                        Create Customized Poster
                    </a> :
                    <button onClick={handleClick} className="logo btn-logo">Create Customized Poster</button>}
                </Nav>

            </Navbar>
        </header>
    );
};
export default observer(Header);
