// import { makeObservable, observable, action } from 'mobx';
import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

class Store {
    eventDetails = null;
    allowScroll = false
    constructor() {
        makeAutoObservable(this);
        makePersistable(this, {
            name: 'store',
            properties: ['eventDetails', 'allowScroll'],
            storage: window.localStorage,
            expireIn: 1800000, // 30 minutes in milliseconds
            removeOnExpiration: true,
            expireCallback: () => {
                this.clearData(); // Call a method to clear the expired data
            }
        });
    }

    updateEvent(details) {
        this.eventDetails = details;
    }
    setAllowScroll(){
        this.allowScroll=true
    }
    clearAllowScroll(){
        this.allowScroll=false
    }

    clearData() {
        // Reset the values to their initial state or clear as needed
        this.eventDetails=null
        this.allowScroll=false
    }
}

const store = new Store();

export default store;
