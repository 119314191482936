import { useState, useEffect } from "react";
import store from "../store";
import axios from "axios";
import { useParams } from "react-router-dom";
import { baseUrl } from "../baseUrl";
import CanvasEditor from "./CanvasEditor";
import ErrorPage from "./ErrorPage";

const PosterPage = () => {
    const { eventSlug, uploadbtn } = useParams();
    const [showPage, setShowPage] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        async function getEventDetails() {
            try {
                const response = await axios.get(`${baseUrl}events/endUsers/${eventSlug}`)
                //  console.log(response.data[0])
                let currentDate = new Date().toISOString();
                if (response.data[0].endDate >= currentDate) {
                    // setEventDetails(response.data[0])
                    store.updateEvent(response.data[0])                    
                } else {
                    setErrorMessage('Event DP Generator has expired.')
                }
                setShowPage(true)
            } catch (error) {
                return Promise.reject('Unable to fetch, status: ');
            }
        }
        getEventDetails();
    }, [])

    useEffect(()=>{
        store.setAllowScroll()
    },[])


    if (!showPage) {
        return null
    } else if (showPage && errorMessage) {
        return (<ErrorPage error={errorMessage} />)
    }
    return (
        store.eventDetails && <CanvasEditor />
    );
}

export default PosterPage;
