import React from 'react'

const ErrorPage = ({error}) => {
  return (
    <div style={{height: '70vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
        <h3>{error}</h3>
    </div>
  )
}

export default ErrorPage