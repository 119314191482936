import { useRef, useState, useEffect } from "react";
import { Button, Col } from "reactstrap";
import store from "../store";
import {
    TransformWrapper,
    TransformComponent,
    useControls
} from "react-zoom-pan-pinch";
import canvasImage from '../images/Campaign.jpeg'
import axios from "axios";
import html2canvas from "html2canvas";
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import { useParams } from "react-router-dom";
import { baseUrl } from "../baseUrl";


const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
        <div>
            <div className="my-2 d-none d-sm-block">
                <Button onClick={() => zoomIn()} className="button1">Zoom In</Button>
            </div>
            <div className="my-2 d-none d-sm-block">
                <Button onClick={() => zoomOut()} className="button1">Zoom Out</Button>
            </div>
            <div className="my-2">
                <Button onClick={() => resetTransform()} className="button1">Reset</Button>
            </div>
        </div>
    );
};

const CanvasEditor = () => {
    const { eventDetails } = store
    const canvasRef = useRef(null);
    const elementRef = useRef(null);

    const outputDiv = {
        position: 'relative',
        width: '100%',
    }

    const innerDiv = {
        position: 'absolute',
        top: `${(eventDetails.top / eventDetails.contHeight) * 100}%`,
        left: `${(eventDetails.left / eventDetails.contWidth) * 100}%`,
        width: `${(eventDetails.width / eventDetails.contWidth) * 100}%`,
        height: `${(eventDetails.height / eventDetails.contHeight) * 100}%`,
        borderRadius: `${eventDetails.borderRadius}%`,
        overflow: 'hidden',
        backgroundColor: '#ffffff'
    }
    const handleImageUpload = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const image = new Image();
                image.onload = () => {
                    // console.log(image.height, image.width)
                    const canvas = canvasRef.current;
                    const ctx = canvas.getContext("2d");
                    let scale = 1;
                    let rotation = 0;
                    let offsetX = 0;
                    let offsetY = 0;

                    canvas.width = 800;
                    canvas.height = 800;

                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.save();
                    ctx.translate(canvas.width / 2, canvas.height / 2);
                    ctx.rotate(rotation * Math.PI / 180);
                    ctx.scale(scale, scale);
                    ctx.drawImage(image, -image.width / 2 + offsetX, -image.height / 2 + offsetY);
                    ctx.restore();
                };

                image.src = e.target.result;
            };

            reader.readAsDataURL(file);
        }
    }
    const handleDownload = () => {
        if (elementRef.current) {
            // console.log(elementRef.current)
            html2canvas(elementRef.current, { useCORS: true }).then((canvas) => {
                //without using any libraries for download
                // const link = document.createElement("a");
                // link.download = "snapshot.png";
                // link.href = canvas.toDataURL();
                // link.click();
                canvas.toBlob(blob => {
                    // Save the blob as a file using FileSaver.js
                    saveAs(blob, 'snapshot.png');
                });
            });
        }
    }

    return (
        <Col md={{ size: 8, offset: 2 }}>
            <h1 className="h1-main">{eventDetails.title}</h1>
            <h3 className="h3-main my-3">{eventDetails.excerpt}</h3>
            {eventDetails.eventLink ? <p>Event Registration: <a href={eventDetails.eventLink} className="reg-link" target="_blank">{eventDetails.linkText ? `${eventDetails.linkText}` : 'Click here'}</a></p> : null}
            {eventDetails.rsvp ? <p><strong>RSVP:</strong> {eventDetails.rsvp}</p> : null}
            <TransformWrapper limitToBounds={false} minScale={0.1} initialScale={1}>
                <div style={outputDiv} id="outputDiv" ref={elementRef} >
                    <img src={`${eventDetails.posterUrl}?x-request=xhr`} width="100%" height="auto" alt="poster image" crossOrigin="anonymous" />
                    <div style={innerDiv}>
                        <TransformComponent>
                            <canvas id="canvas" ref={canvasRef} data-read-frequently={true} />
                        </TransformComponent>
                    </div>
                </div>
                <div className="my-2" style={{color:'red', fontWeight:'bold'}}>Important: On mobile, use fingers to zoom, pan and pinch image after upload.</div>
                <div className="mt-5 my-2" id="upload-btn">
                    <input type="file" accept="image/*" onChange={handleImageUpload} className="input" />
                </div>
                <Controls handleImageUpload={handleImageUpload} />
                <div className="my-2">
                    <Button onClick={handleDownload} className="button1">Download</Button>
                </div>
            </TransformWrapper>
        </Col>
    );
}

export default CanvasEditor;
