import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
    const year = new Date().getFullYear();
    return (
        //  FOOTER 
        <footer>
            <div className="footer">
                <div className="footer-top">
                        <div className="footer-links">
                            <a className="foot-logo logo navlinks" href="https://postlify.live" target='_blank'>Postlify</a>
                        </div>
                        <div className="footer-links">
                            <Link className="navlinks white-text" to='/privacy-policy' target='_blank'>Privacy Policy</Link>
                        </div>
                </div>
                <div className="footer-bottom">
                    <small>©{year} cymworx.com, All rights reserved.</small>
                    <small>Created by <a href="https://cymworx.com" target="_blank" className='dark-theme'>Cymworx Inc.</a></small>
                </div>

            </div>
        </footer>
    )
}

export default Footer